<template>
  <section class="pb-8">
    <div class="container">
      <div class="order-bg mb-3 mb-md-8 success">
        綁定中請稍後...
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  created() {
    this.LineAuth();
  },
  methods: {
    LineAuth() {
      const { errorNumber, errorMessage } = this.$route.query;
      if (errorNumber !== 0) {
        this.$message({
          showClose: true,
          type: 'error',
          message: errorMessage,
        });
      }
      this.$router.replace('/member/info');
    },
  },
};
</script>

<style></style>
